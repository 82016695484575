@use '@angular/material' as mat;
@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
    50 : #e6e9ef,
    100 : #bfc8d6,
    200 : #95a3bb,
    300 : #6b7e9f,
    400 : #4b628b,
    500 : #2b4676,
    600 : #263f6e,
    700 : #203763,
    800 : #1a2f59,
    900 : #102046,
    A100 : #80a1ff,
    A200 : #4d7bff,
    A400 : #1a55ff,
    A700 : #0142ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);



$my-app-primary: mat.define-palette($md-mcgpalette0);
$my-app-accent:  mat.define-palette(mat.$pink-palette, 500, 900, A100);
$my-app-warn:    mat.define-palette(mat.$deep-orange-palette);
$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include mat.all-component-themes($my-app-theme);