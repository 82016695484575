/* You can add global styles to this file, and also import other style files */
@import '~@stream-io/stream-chat-css/dist/scss/index.scss';

html, body { height: 100%; }
body { margin: 0;  }

.loader {
    position: absolute;
    // above table column
    z-index: 999 !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: rgba(43,70,118,255);
    z-index: 100;
    font-size: 3.8rem;
    transition: all ease-in 300ms;
    transition-property: height, width;
    text-align: center;
    animation: 1s ease-in 1s 2 alternate font-animation;
    
    .cache {
        position: absolute;
        width: 0;
        height: 100%;
        background-color: white;
        right: 0px;
        animation: 1s ease-in 0s 1 alternate letter-animation;

        &.hidden {
            z-index: 50;
        }
    }

    &.hidden {
        width: 50%;
        height: 10%;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .loader {
        margin: auto;
        font-size: 1.8rem;
        transition: all ease-in 300ms;
        background-color: white;
        transition-property: height, width;
        text-align: center;
        animation: 1s ease-in 1s 2 alternate font-animation-small;
        
        .cache {
            position: absolute;
            width: 0;
            height: 100%;
            background-color: white;
            right: 0px;
            animation: 1s ease-in 0s 1 alternate letter-animation;
    
            &.hidden {
                z-index: 50;
            }
        }
    
        &.hidden {
            width: 100%;
            height: 10%;
            font-size: 1rem;
        }
    }
}

@keyframes font-animation { from { font-size: 3.8rem; } to { font-size: 4rem; }  }
@keyframes font-animation-small { from { font-size: 1.8rem; } to { font-size: 2rem; }  }
@keyframes letter-animation { from { width: 100%; } to { width: 0%; }  }

body { margin: 0; font-family: "Helvetica Neue", sans-serif; }
